import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import { Box, CardMedia, Divider, CardActions, Button } from '@material-ui/core';

const styles = theme => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

class AppCard extends Component {
    render() {
        const { title } = this.props;
        const { backgroundColor } = this.props;
        const { fontColor } = this.props;
        const { image } = this.props;
        const { imageText } = this.props;
        const { text } = this.props;
        const { classes } = this.props;
        const { playstore } = this.props;
        const { github } = this.props;

        return (
            <Card className={classes.root}>
                {image != null ? (
                    <Box>
                        <CardMedia
                            className={classes.media}
                            image={image}
                            title={imageText} />
                        <Typography variant="h3" component="h2" color={backgroundColor}>
                            {title}
                        </Typography>
                    </Box>
                ) : (
                    <Box bgcolor={backgroundColor} color={fontColor}>
                        <Typography variant="h3" component="h2" color={backgroundColor}>
                            {title}
                        </Typography>
                    </Box>
                )}
                <Divider />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {text}
                    </Typography>
                </CardContent>
                <CardActions>
                {playstore != null ? (
                        <Button size="small" color="primary" href={playstore}>
                            Google Play Store
                        </Button>
                    ):( <Box/> )}
                    {github != null ? (
                        <Button size="small" color="primary" href={github}>
                            GitHub
                        </Button>
                    ):( <Box/> )}
                </CardActions>
            </Card>
        )
    }
}

export default withStyles(styles)(AppCard);