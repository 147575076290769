import { Box, Paper } from "@material-ui/core";
import React from "react";
import { db } from "../firedb";
import CSError from "./error";

function Privacy() {
    const [privacy, setPrivacy] = React.useState(null);
    const [readError, setReadError] = React.useState(null);
    React.useEffect(() => {
        try {
            let dbResult = db.ref("privacy");
            dbResult.on("value", snapshot => {
                let privacy = null;
                snapshot.forEach((snap) => {
                    privacy = snap.val();
                });
                setPrivacy(privacy);
            });
        } catch (error) {
            setReadError(error.message);
        }
    }, []);

    if (readError != null)
        return (
            <CSError readError={readError}></CSError>
        );
    else
        return (
            <Box p={2}>
                <Paper>
                    <Box p={2} dangerouslySetInnerHTML={{ __html: privacy }}>
                    </Box>
                </Paper>
            </Box>
        );
}

export default Privacy;