import { Box, Container, Paper } from "@material-ui/core";

function CSError(props) {
    return (
        <Box m={2}>
          <Container>
            <Paper>Error loading product list, please contact site owner.  {props.readError} </Paper>
          </Container>
        </Box>
      );
  }

  export default CSError;