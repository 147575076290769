import React from "react";
import CsCard from './card';
import Grid from "@material-ui/core/Grid";
import { Box, Container, Button } from "@material-ui/core";
import { Email } from "@material-ui/icons";

function Home(props) {
    let email = "mailto:terence@coffeeshopstudio.ca";
    for (var i = 0; i < props.pages.length; i++) {
        if (props.pages[i].title === "Home") {
            email = props.pages[i].contact;
            props.pages.splice(i, 1);
        }
    }

    return (
        <Box m={2}>
            <Container>
                <Grid container justify="center" alignItems="center" direction="row"
                    spacing={2}>
                    {props.pages.map(page => {
                        return <Grid item md={3} key={page.key}>
                            <CsCard title={page.title} image={page.banner} text={page.details} link={page.link} />
                        </Grid>
                    })}
                </Grid>
                <Box p={2}>
                    <Grid container justify="flex-end">
                        <Button variant="contained" color="secondary" aria-label="email" component="a" href={email}>
                            Contact Me&nbsp;<Email />
                        </Button>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default Home;