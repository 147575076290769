import firebase from 'firebase';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDtzuc9Y_ABlIqVFmSxVCgSaOohAfoOTyE",
    authDomain: "home-page-b835d.firebaseapp.com",
    databaseURL: "https://home-page-b835d.firebaseio.com",
    projectId: "home-page-b835d",
    storageBucket: "home-page-b835d.appspot.com",
    messagingSenderId: "925076389123",
    appId: "1:925076389123:web:e9828064d233081eb47d36"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.database();