import React from "react";
import './App.css';
import Home from './cs/home';
import CSError from './cs/error';
import Products from './cs/products';
import Privacy from './cs/privacy';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import TopBar from './cs/topBar'
import { db } from "./firedb";
import { Box, createMuiTheme, CssBaseline, Divider, Grid, ThemeProvider, useMediaQuery } from "@material-ui/core";
import teal from '@material-ui/core/colors/teal';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
          primary: teal,
          secondary: {
            main: '#2196f3',
          },
        },
      }),
    [prefersDarkMode],
  );

  const [pages, setPages] = React.useState([]);
  const [readError, setReadError] = React.useState(null);
  React.useEffect(() => {
    try {
      let dbResult = db.ref("about/article");
      dbResult.orderByChild("priority").on("value", snapshot => {
        let pages = [];
        snapshot.forEach((snap) => {
          let page = snap.val();
          page.key = snap.key;
          pages.push(page);
        });
        setPages(pages);
      });
    } catch (error) {
      setReadError(error.message);
    }
  }, []);

  if (readError != null)
    return (
      <CSError readError={readError}></CSError>
    );
  else
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box>
          <Router>
            <div>
              <TopBar></TopBar>
              <Switch>
                <Route path="/privacy">
                  <Privacy />
                </Route>
                <Route path="/products" >
                  <Products />
                </Route>
                <Route path="/">
                  <Home pages={pages} />
                </Route>
              </Switch>
            </div>
          </Router>
          <Divider />
          <Grid m={2} container justify="center">
            ©{getYear()} T Doerksen <br />
          </Grid>
        </Box>
      </ThemeProvider>
    );
}

function getYear() {
  return new Date().getFullYear();
}

export default App;
