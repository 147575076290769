import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

class CsCard extends Component {
    render() {
        const { title } = this.props;
        const { link } = this.props;
        const { image } = this.props;
        const { imageText } = this.props;
        const { text } = this.props;
        const { classes } = this.props;
        return (
            <Card className={classes.root}>
                <CardActionArea href={link}>
                    <CardMedia
                        className={classes.media}
                        image={image}
                        title={imageText}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {text}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
}

export default withStyles(styles)(CsCard);