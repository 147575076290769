import React from "react";
import AppCard from './appcard';
import Grid from "@material-ui/core/Grid";
import { Box, Container } from "@material-ui/core";
import { db } from "../firedb";
import CSError from "./error";

function Products() {
    const [products, setProducts] = React.useState([]);
    const [readError, setReadError] = React.useState(null);
    React.useEffect(() => {
        try {
            let dbResult = db.ref("products");
            dbResult.orderByChild("priority").on("value", snapshot => {
                let products = [];
                snapshot.forEach((snap) => {
                    if (!snap.val().hidden) {
                        let product = snap.val();
                        product.key = snap.key;
                        products.push(product);
                    }
                });
                setProducts(products);
            });
        } catch (error) {
            setReadError(error.message);
        }
    }, []);

    if (readError != null)
        return (
            <CSError readError={readError}></CSError>
            );
    else
        return (
            <Box m={2}>
                <Container>
                    <Grid container justify="center" alignItems="center" direction="row"
                        spacing={2}>
                        {products.map(product => {
                            return <Grid item md={3} key={product.key}>
                                <AppCard text={product.details} github={product.github} title={product.title} playstore={product.playstore} image={product.banner} backgroundColor={product.backgroundColor} fontColor={product.fontColor} />
                            </Grid>
                        })}
                    </Grid>
                </Container>
            </Box>
        );
}

export default Products;